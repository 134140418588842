var Utils = function() {
    return {
        init: function() {

        },

        handleInputMask: function() {
            $(".mask-time").inputmask("99:99", {numericInput: true});
            $(".mask-zip").inputmask("99.999-999");
            $(".mask-phone").inputmask("mask", {"mask": "(99) 9999-9999[9]"});
            $('.mask-cnpj').inputmask("99.999.999/9999-99");
            $(".mask-number").inputmask({"mask": "9", "repeat": 5, "greedy": false});
            $('.mask-money').maskMoney({"thousands": ".", "decimal": ","});
            $('.mask-money').maskMoney({"thousands": ".", "decimal": ","});
            // $(".mask-weight").inputmask("99999.999");
            $('.mask-weight').inputmask("9{1,3}.9{3}");
        },

        handleSwitch: function() {
            $(".make-switch").bootstrapSwitch();
        },

        handleTagsInput: function() {
            $(".tagsinput").tagsinput({
                tagClass: "label label-success",
                maxTags: 15
            });
        },

        handleDatePicker: function() {
            $(".date-picker").datepicker({
                rtl: App.isRTL(),
                language: "pt-BR",
                orientation: "right",
                autoclose: true,
                todayHighlight: true,
                format: 'dd/mm/yyyy'
            });
        },

        handleTimePicker: function() {
            $(".timepicker-24").timepicker({
                autoclose: true,
                minuteStep: 5,
                showSeconds: false,
                showMeridian: false,
                defaultTime: false
            });
        },

        handleSelectSplitter: function() {
            $('.selectsplitter').selectsplitter({
                selectSize: 5
            })
        },

        handleFancybox: function() {
            $(".fancybox").fancybox({
                type: 'iframe',
                showNavArrows : false,
                hideOnContentClick: true,
                helpers: {
                    overlay: {
                        locked: false
                    }
                }
            });
            $(".fancybox-image").fancybox({
                type: 'image',
                showNavArrows : false,
                hideOnContentClick: true,
                helpers: {
                    overlay: {
                        locked: false
                    }
                }
            });
            $(".fancybox-image-2").fancybox({
                fitToView: false
            });
        },

        handleModals: function() {
            $.fn.modal.defaults.spinner = $.fn.modalmanager.defaults.spinner =
                '<div class="loading-spinner" style="width: 200px; margin-left: -100px;">' + '<div class="progress progress-striped active">' +
                '<div class="progress-bar" style="width: 100%;"></div>' + '</div>' + '</div>';
            $.fn.modalmanager.defaults.resize = true;

            // Modal de resposta de contato para produto
            var $modal_contact_answer = $('#modal_contact_answer');
            $('.contact_answer').on('click', function(){
                var id = $(this).attr('data-id');
                $('body').modalmanager('loading');
                setTimeout(function(){
                    $modal_contact_answer.load(st_u + 'products/answer/' + id, '', function(){
                        $modal_contact_answer.modal();
                    });
                }, 100);
            });

            // Modal de estatísticas do produto
            var $modal_product_stats = $('#modal_product_stats');
            $('.product_stats').on('click', function(){
                var id = $(this).attr('data-id');
                $('body').modalmanager('loading');
                setTimeout(function(){
                    $modal_product_stats.load(st_u + 'products/stats/' + id, '', function(){
                        $modal_product_stats.modal();
                    });
                }, 100);
            });

            // Modal de resposta de contato na caixa de entrada
            var $modal_message_answer = $('#modal_message_answer');
            $('.message_answer').on('click', function(){
                var id = $(this).attr('data-id');
                $('body').modalmanager('loading');
                setTimeout(function(){
                    $modal_message_answer.load(st_u + 'contacts/answer/' + id, '', function(){
                        $modal_message_answer.modal();
                    });
                }, 100);
            });

            // Modal de para abrir tela itau
            $('#modal-billing').on('click', function(){
                var $modal = $('#barcode-itau');
                var id = $(this).attr('data-id');
                $('body').modalmanager('loading');

                setTimeout(function(){
                    $modal.load(st_u + 'billings/billetItau/' + id, function(response, status, xhr){
                        $modal.modal();
                    });
                }, 500);
            });

          // Modal de solicitar edição na campanha Google
          var $modal_edit_campaign = $('#modal_edit_campaign');
          $('.edit_campaign').on('click', function(){
            var id = $(this).attr('data-id');
            $('body').modalmanager('loading');
            setTimeout(function(){
              $modal_edit_campaign.load(st_u + 'campaigns/callEdit/' + id, '', function(){
                $modal_edit_campaign.modal();
              });
            }, 100);
          });

        },

        timeOutRedirect: function(time, location) {
            if (time === undefined) {
                time = 5000;
            }
            if (location === undefined) {
                location = st_u;
            }
            window.setTimeout(function() {
                window.location.href = location;
            }, time);
            return true;
        },

        validateImageSize: function(file, width, height) {
            var ret = $.Deferred();
            try {
                if (file.type.match(/image\/(png|jpeg|gif)/im) === null) {
                    ret.reject('O arquivo enviado não é uma imagem válida');
                    return ret;
                }

                var reader = new FileReader();

                reader.onload = function() {
                    var img = new Image();
                    img.src = this.result;

                    img.onload = function() {
                        if (img.naturalWidth === 0 && img.naturalHeight === 0) {
                            return ret.reject('O arquivo enviado não é uma imagem válida');
                        }

                        // valida as duas regras ao mesmo tempo
                        if ((width && height) && (img.naturalWidth !== width || img.naturalHeight !== height)) {
                            return ret.reject('As dimensões da imagem devem ser exatamente ' + width + 'x' + height + ' pixels');
                        }

                        if (width && img.naturalWidth !== width) {
                            return ret.reject('A largura da imagem deve ser exatamente ' + width + ' pixels');
                        }

                        if (height && img.naturalHeight !== height) {
                            return ret.reject('A altura da imagem deve ser exatamente ' + height + ' pixels');
                        }

                        ret.resolve();
                    };

                    img.onerror = function() {
                        return ret.reject('Não foi possível ler a imagem');
                    };
                };

                reader.onerror = function() {
                    return ret.reject('Não foi possível ler o arquivo');
                };

                reader.readAsDataURL(file);
            } catch (e) {
                ret.reject('Ocorreu um erro interno');
            } finally {
                return ret;
            }
        },

        alertErrorArray: function(arr) {
            if (arr instanceof Array) {
                alert("• "+arr.join("\r\n• "));
            }
        }
    };
}();
jQuery(document).ready(function() {

    Utils.init();

});
